import React from "react";
import "./App.css";
import {post} from "./service.js"

const Loader = () => (
  <div class="divLoader">
    <svg class="svgLoader" viewBox="0 0 200 200" width="10em" height="10em">
	  <text x="20" y="55" style={{fontWeight: 'bold'}}>loading...</text>
      <path stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#51CACC" transform="rotate(179.719 50 51)">
		  <animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite">
		  </animateTransform>
	  </path>
    </svg>
  </div>
);


class App extends React.Component {
	
	
  componentDidMount(){
    document.title = "Remote Solution"
  }
  constructor(props) {
    super(props);
    this.state = {
	  loading: false,
      complaint : "",
      curswver : "",
      tvswver : "",
      complaintprediction: "",
      wrapup1: "",
      wrapup2 : "",
      category : "",
      status : "",
      message : "",
      hideerror : true,
      hideresults : true,
      hideinputfielderror : true

    }


  }

  render() {
      return (
        <div class="mx-auto" style={{ width: "85%" }}>
          <div style={{ width: "100%" }}>
		  
		  <img class="img-fluid" src={`${process.env.PUBLIC_URL}/tpvision.gif`} alt="logo"/>
            <h1 class="m-3" style={{color:"blue",textAlign: "center"}}>AI Recommendation for Remote Solution</h1>
          </div>
          <span style={{color: "red"}} hidden={this.state.hideinputfielderror}>Enter a valid customer complaint, TV SW and latest SW details</span>
		  
		  <div class="mb-3 row" title="customer complaints, minimum 8 words requires">
			<label for="exampleFormControlInput1" class="col-sm-2 col-form-label text-primary" >Customer Complaint</label>
			<div class="col-sm-10">
			<textarea class="form-control" key="compliant" name="complaint" type="text" value={this.state.complaint} onChange={event => {this.handleInputChange(event);}}
              required
            />
		  </div>
		  </div>
		  
		  <div class="mb-3 row" title="Current software version in customer TV">
		   <label for="exampleFormControlTextarea1" class="col-sm-2 col-form-label text-primary">TV SW Version</label>
		   <div class="col-sm-10" title="give current software version in customer TV in format e.g. 101.002.098.000">
		   <input class="form-control" key="tvswver" name="tvswver" type="text" value={this.state.tvswver} placeholder="e.g: 101.002.098.000 or NA" onChange={event => {
                this.handleInputChange(event);
              }}
			  required 
            />
		   </div>
		   </div>

		  <div class="mb-3 row" title="Current software version in available in CTN">
		   <label for="exampleFormControlTextarea1" class="col-sm-2 col-form-label text-primary">Current Available SW Version</label>
		   <div class="col-sm-10" title="Give current software version in available in CTN in format e.g. 201.003.160.001">
		   <input class="form-control" key="curswver" name="curswver" type="text" value={this.state.curswver} placeholder="e.g: 201.003.160.001 or NA" onChange={event => {
                this.handleInputChange(event);
              }}
			  required 
            />
		   </div>
		   </div>
		  
		  
          <div class="mx-auto" style={{ width: "70%" }}>
            <button type="button" class="btn btn-primary m-2" onClick={(event) =>{this.handleSubmit()}}>Get Recommendations</button>
            <button type="button" class="btn btn-light  m-2"  onClick={(event) =>{this.clearForm()}}>Clear</button>
			{this.state.loading ? <Loader /> : null}
          </div>

          <div style={{ width: "100%"}} hidden={this.state.hideerror}>
            <p class="text-danger m-5">Error: Could not retrieve data from Server</p>
          </div>

          <div style={{ width: "100%" }} hidden={this.state.hideresults}>
            <div >
              <h1 class="text-warning m-4" style={{ textAlign: "center" }}> Recommendation Results</h1>
              <table class="table table-bordered table-sm">
                <tbody>
                  <tr>
                    <td><b>Complaint Text Classification</b></td>
                    <td><b>Wrapup 1 prediction</b></td>
                    <td><b>Wrapup 2 prediction</b></td>
                    <td><b>Category</b></td>
                  </tr>
				  <tr>
                    <td>{this.state.complaintprediction}</td>
                    <td>{this.state.wrapup1}</td>
                    <td>{this.state.wrapup2}</td>
                    <td>{this.state.category}</td>
                  </tr>
                </tbody>
              </table>
			  <table class="table table-bordered table-sm">
			    <colgroup>
					<col class="col-md-2"/>
					<col class="col-md-7"/>
				</colgroup>
				<thead>
					<tr>
					  <th scope="col">Status</th>
					  <th scope="col">Message</th>
					</tr>
				</thead>
                <tbody>
				  <tr>
                    <td>{this.state.status}</td>
                    <td>{this.state.message}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      );
    }

   

    handleInputChange(event) {
      const target = event.target;
      const value = target.type === "checkbox" ? target.checked : target.value;
      const name = target.name;
		
	  
      this.setState({
        [name]: value,
        hideinputfielderror: true
      });
    }
  
    handleSubmit(event) {
      let complaint  = this.state.complaint;
      let tvswver  = this.state.tvswver;
      let curswver  = this.state.curswver;
	  
      if((!complaint) || (complaint.trim() === "") || (!tvswver) || (tvswver.trim() === "") || (!curswver) || (curswver.trim() === "")){
        this.setState({
          hideinputfielderror: false
        });
        return;
      }
	  
	  this.setState({
          loading: true,
          hideresults: true,
		  hideerror : true
        });	
      post( this.state.curswver, this.state.tvswver, this.state.complaint, (result) =>{
        if(result){
         let resultJson = JSON.parse(result);
         this.setState({
		  loading: false,
          complaintprediction: resultJson.complaintprediction,
          wrapup1: resultJson.wrapup1,
          wrapup2 : resultJson.wrapup2,
          category : resultJson.category,
          status : resultJson.status,
          message : resultJson.message,
          hideerror : true,
          hideresults : false,
          hideinputfielderror: true
        });         

        }else{
          this.setState({
            loading : false,
            hideerror : false,
            hideresults : true,
            hideinputfielderror: true
          });
        }
      });
   
    }

    clearForm(){
      this.setState({
        complaint : "",
        curswver : "",
        tvswver : "",
        complaintprediction: "",
        wrapup1: "",
        wrapup2 : "",
        category : "",
        status : "",
        message : "",
        hideerror : true,
        hideresults : true,
		hideinputfielderror : true
      });
    }

}

export default App;
