export function post(curswver, tvswver, complaint, callback){	
    fetch("/api/prediction", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({curswver,tvswver,complaint})
    })
    .then( (response) => {
        console.log(response.status);
        if(response.status === 200){
            response.text().then((text)=>{
            console.log(text);
            callback(text);
          });
        }else{
          callback(false);
        }
    }).catch (function (error) {
        console.log('Request failed', error);
        callback(false);
    });
  };